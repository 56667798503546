// import { reactive } from 'vue'
// import Vuex from 'vuex'

import { createStore } from 'vuex'

export default createStore({
    state: {
        serveur: 'https://gestion-rdv-backend.lce-ci.com/',
        user: JSON.parse(localStorage.getItem("user")) || null,
        token: localStorage.getItem("token") || '',
        role: localStorage.getItem("role") || 'none'
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})