<template>


    <div class="iq-top-navbar m-0">
        <div class="content-top">
            <div class="iq-navbar-custom">
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <ul class="d-flex nav nav-pills mb-4 text-center event-tab" id="event-pills-tab" role="tablist">
                            <li class="nav-item" v-show="this.$store.state.role == 'user'">
                                <router-link to="/admin/dashboard" id="view-btn" class="nav-link"
                                    :class="{ active: dashActive }" role="tab">Opérateur</router-link>
                            </li>
                            <li class="nav-item"
                                v-show="this.$store.state.role == 'admin' || this.$store.state.role == 'admin_user'">
                                <router-link to="/admin/dashboard" id="view-btn" class="nav-link"
                                    :class="{ active: dashActive }" role="tab">Dashboard</router-link>
                            </li>
                            <li class="nav-item"
                                v-show="this.$store.state.role == 'admin' || this.$store.state.role == 'admin_user'">
                                <router-link to="/admin/operations" class="nav-link" :class="{ active: opeActive }"
                                    role="tab" aria-selected="false">Specialités</router-link>
                            </li>
                            <li class="nav-item"
                                v-show="this.$store.state.role == 'admin' || this.$store.state.role == 'admin_user'">
                                <router-link to="/admin/utilisateurs" class="nav-link" :class="{ active: utiActive }"
                                    role="tab" aria-selected="false">Utilisateurs</router-link>
                            </li>
                            <li class="nav-item"
                                v-show="this.$store.state.role == 'admin' || this.$store.state.role == 'admin_user'">
                                <router-link to="/admin/rdvs" class="nav-link" :class="{ active: rdvActive }" role="tab"
                                    aria-selected="false">Rendez-vous</router-link>
                            </li>
                            <li class="nav-item"
                                v-show="this.$store.state.role == 'admin' || this.$store.state.role == 'admin_user'">
                                <router-link to="/admin/statistiques" class="nav-link" :class="{ active: StatActive }" role="tab"
                                    aria-selected="false">Statistique</router-link>
                            </li>
                        </ul>
                    </div>
                    <nav class="navbar navbar-expand-lg navbar-light p-0">
                        <div class="change-mode">
                            <div class="custom-control custom-switch custom-switch-icon custom-control-indivne">
                                <div class="custom-switch-inner">
                                    <p class="mb-0"> </p>
                                    <input type="checkbox" class="custom-control-input" id="dark-mode"
                                        data-active="true">
                                    <label class="custom-control-label" for="dark-mode" data-mode="toggle">
                                        <span class="switch-icon-left"><i class="a-left ri-moon-clear-line"></i></span>
                                        <span class="switch-icon-right"><i class="a-right ri-sun-line"></i></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ml-auto navbar-list align-items-center">
                                <li class="caption-content">
                                    <a href="#" class="search-toggle dropdown-toggle d-flex align-items-center"
                                        id="dropdownMenuButton3" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <div class="caption ml-3">
                                            <h6 class="mb-0 line-height">{{ $store.state.user.nom }}
                                                {{ $store.state.user.prenom }}<i class="las la-angle-down ml-3"></i>
                                            </h6>
                                        </div>
                                    </a>
                                    <div class="iq-sub-dropdown dropdown-menu user-dropdown"
                                        aria-labelledby="dropdownMenuButton3">
                                        <div class="card m-0">
                                            <div class="card-body p-0">
                                                <router-link to="/conins"
                                                    class="right-ic p-3 border-top btn-block position-relative text-center"
                                                    role="button">
                                                    Déconnexion
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>



</template>


<script>

export default {
    name: 'HeadVue',
    props: {
        dashActive: {
            type: Boolean,
            required: false,
            default: false,
        },
        opeActive: {
            type: Boolean,
            required: false,
            default: false,
        },
        rdvActive: {
            type: Boolean,
            required: false,
            default: false,
        },
        utiActive: {
            type: Boolean,
            required: false,
            default: false,
        },
        StatActive: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

}

</script>