<template>

    <HeadVue navHomeActive="true"/>
    <EntrepriseContainerVue :secteurId="this.$route.params.secId" :secteurLibelle="this.$route.params.secLib"/>
    <FootVue />

</template>

<script>
import FootVue from '@/components/FootVue.vue';
import HeadVue from '@/components/HeadVue.vue';
import EntrepriseContainerVue from '@/components/EntrepriseContainerVue.vue';
// @ is an alias to /src


export default {
  name: 'EntrepriseView',
  components: {
    HeadVue,
    FootVue,
    EntrepriseContainerVue,
}
}
</script>