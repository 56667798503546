<template>
    <div class="d-flex justify-content-center" :class="margin">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LoaderVue',
    props: {
        margin: {
            type: String,
            required: false,
            default: ''
        }

    }
}

</script>