<template>

    <HeadVue navConInsActive="true"/>
    <ConInsContainerVue />
    <FootVue />

</template>

<script>
import FootVue from '@/components/FootVue.vue';
import HeadVue from '@/components/HeadVue.vue';
import ConInsContainerVue from '@/components/ConInsContainerVue.vue';
// @ is an alias to /src


export default {
  name: 'ConInsView',
  components: {
    HeadVue,
    FootVue,
    ConInsContainerVue
}
}
</script>