import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store/index.js'
import { DataTable } from 'datatables.net'

import axios from 'axios'

axios.defaults.headers.common.Authorization = 'Bearer ' + store.state.token

createApp(App).use(store).use(router).use(DataTable).mount('#app')

