<template>

    <HeadVue navHomeActive="true"/>
    <HomeContainerVue />
    <FootVue />

</template>

<script>
import FootVue from '@/components/FootVue.vue';
import HeadVue from '@/components/HeadVue.vue';
import HomeContainerVue from '@/components/HomeContainerVue.vue';
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
      HeadVue,
      FootVue,
      HomeContainerVue
  }
}
</script>
