<template>

    <div class="content-page  text-center">
        <table class="styled-table center animate__animated animate__repeat-5" id="table">
            <thead>
                <tr>
                    <th>Code</th>
                    <th class="text-right">Guichet</th>
                </tr>
            </thead>
            <tbody>
                
                <tr class="active-row" v-for="(wait,index) in getFile" :key="index">
                    <td class="text-left">{{wait.code}}</td>
                    <td class="text-right">{{wait.operation_id}}</td>
                </tr>
                
            </tbody>
        </table>
    </div>
</template>
<script>

    import axios from 'axios'
    import Pusher from 'pusher-js';
    import 'animate.css';
    
        export default {

            name: 'app',

            

            data() {
                return {
                    waitingline: []
                }
            },
            computed: {
                getFile() {
                    let app = this
                    Pusher.logToConsole = true;
                    // Local
                    // var pusher = new Pusher('c262790826e375779b62', {
                    // cluster: 'eu'
                    // });
                    var pusher = new Pusher('2fe73302d0a26880309c', {
                        cluster: 'eu'
                    });

                    var channel = pusher.subscribe('my-channel');
                    channel.bind('my-event', function(data) {
                        console.log('testdata',data)
                        app.waitingline = data.message
                        var element = document.getElementById("table");
                        element.classList.add("animate__flash");
                    });



                    return this.waitingline;
                },
                
            },
            methods: {
                getSecteur() {
                    this.load = true
                    axios.get(this.$store.state.serveur + 'api/service/1')
                        .then((response) => {
                            console.log('attente',response.data)
                            this.waitingline = response.data.data
                        })
                        .catch(e => (this.dataError = e))
                },
            },

            created() {
                this.getSecteur()
                
            }
            
        };

</script>
<style scoped>
.styled-table {
    border-collapse: collapse;
    margin: 45px 0;
    font-size: 3.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
    background-color: #EB8A05;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 44px 27px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #EB8A05;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #EB8A05;
}

.center {
  margin: auto;
  width: 60%;
  padding: 20px;
}

</style>