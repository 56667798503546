<template>

    <HeadVue navAboutActive="true"/>
    <AboutContainerVue />
    <FootVue />

</template>

<script>
import FootVue from '@/components/FootVue.vue';
import HeadVue from '@/components/HeadVue.vue';
import AboutContainerVue from '@/components/AboutContainerVue.vue';
// @ is an alias to /src


export default {
  name: 'AboutView',
  components: {
    HeadVue,
    FootVue,
    AboutContainerVue
}
}
</script>