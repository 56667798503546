<template>


    <HeadVue utiActive="true" />

    <div class="content-page">
        <div class="col-lg-12 mb-3">
            <div class="d-flex align-items-center justify-content-between">
                <div class="navbar-breadcrumb">
                    <h2 class="mb-1">---- -- ------- ----------</h2>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div>
                    <div class="col-lg-12 mb-3">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="navbar-breadcrumb">
                                <h2 class="mb-1">Créer un nouvel utilisateur</h2>
                            </div>
                        </div>
                    </div>
                    <div class="tab-extra active" id="search-with-button">
                        <div class="d-flex flex-wrap mb-4">
                            <div class="row">
                                <div class="iq-search-bar search-device mb-0 pr-3">
                                    <div class="searchbox" style="width:300px;">
                                        <input v-model="newUser.nom" type="text" class="text search-input"
                                            placeholder="Nom">
                                    </div>
                                    <div class="searchbox" style="width:300px;">
                                        <input v-model="newUser.email" type="mail" class="text search-input"
                                            placeholder="E-Mail">
                                    </div>
                                </div>
                                <div class="iq-search-bar search-device mb-0 pr-3">
                                    <div class="searchbox" style="width:300px;">
                                        <input v-model="newUser.prenoms" type="text" class="text search-input"
                                            placeholder="Prénoms">
                                    </div>
                                    <div class="searchbox" style="width:300px;">
                                        <input v-model="newUser.password" type="password" class="text search-input"
                                            placeholder="Mot de passe">
                                    </div>
                                </div>
                                <div class="iq-search-bar search-device mb-0 pr-3">
                                    <div class="searchbox" style="width:300px;">
                                        <input v-model="newUser.phone" type="text" class="text search-input"
                                            placeholder="Contact">
                                    </div>
                                    <div class="mt-2" style="width:300px;">
                                        <select v-model="newUser.operation" name="operation" id="operation" class="form-control">
                                            <option value="0">---Choix de la spécialité---</option>
                                            <option v-for="operation in listOperation" :value="operation.id"
                                                :key="operation.id">
                                                {{ operation.libelle }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="float-sm-right">
                                <div :class="{ disabled: !newUserFiledFull }" @click="addNewUser"
                                    class="btn btn-primary pr-5 position-relative" style="height: 40px;">Ajouter<span
                                        class="event-add-btn"><i class="ri-add-line"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="width:100%">
                <div class="col-lg-12">
                    <div class="card card-block card-stretch card-height">
                        <div class="card-header d-flex justify-content-between">
                            <div class="iq-header-title">
                                <h2 class="mb-1">Liste des utilisateurs</h2>
                            </div>
                            <LoaderVue v-if="load" />
                            <div>
                                <h5>Nombre total: {{ listUser.length }} </h5>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="card card-block card-stretch">
                                <div class="card-body" v-for="user in listUser" :key="user.id">
                                    <div class="d-flex flex-wrap align-items-center justify-content-between">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <div class="date mr-3">
                                                <h4 class="text-info">
                                                    {{ user.id }}
                                                </h4>
                                            </div>
                                            <div class="border-left pl-3">
                                                <div class="media align-items-top">
                                                    <h5 class="mb-3"> {{ user.nom }} {{ user.prenom }} </h5>
                                                    <!-- <div v-if="user.operation.length != 0"> -->
                                                    <div class="badge badge-warning ml-3" v-for="op in user.operation"
                                                        :key="op.id">
                                                        {{ op.libelle }}
                                                    </div>
                                                    <div class="badge badge-info ml-3" v-for="ro in user.role"
                                                        :key="ro.id">
                                                        {{ ro.libelle }}
                                                    </div>
                                                    <!-- </div> -->
                                                </div>
                                                <div class="media align-items-center">
                                                    <p class="mb-0 pr-3"><i class="ri-phone-line mr-2 text-info"></i>
                                                        {{ user.phone }}
                                                    </p>
                                                    <p class="mb-0"><i class="ri-mail-line  mr-2 text-info"></i>
                                                        {{ user.email }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div @click="setUpNewUser(user)" class="badge mr-3" data-placement="top"
                                            title="Modifier" data-toggle="modal" data-target="#create-event"
                                            data-original-title="Edit"><i class="ri-edit-box-line"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade create-workform" id="create-event" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="popup text-left">
                        <h4 class="mb-3 text-center">Modification utilisateur</h4>
                        <div class="mt-3">
                            <div>
                                <div>
                                    <div class="col-lg-12">
                                        <div class="floating-input form-group">
                                            <input v-model="upNewUser.nom" class="form-control" type="text" name="nom"
                                                id="nom" required />
                                            <label class="form-label" for="nom">Nom </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="floating-input form-group">
                                            <input v-model="upNewUser.prenoms" class="form-control" type="text"
                                                name="prenom" id="prenom" required />
                                            <label class="form-label" for="prenom">Prénoms</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="floating-input form-group">
                                            <input v-model="upNewUser.phone" class="form-control" type="text"
                                                name="phone" id="phone" required />
                                            <label class="form-label" for="phone">Contact </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="floating-input form-group">
                                            <input v-model="upNewUser.email" class="form-control" type="text"
                                                name="email" id="email" required />
                                            <label class="form-label" for="email">E-Mail </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="col-lg-6">
                                        <div v-for="operation in listOperation" :key="operation.id">
                                            <input @click="toogleOperation(operation.id)"
                                                v-if="upNewUser.operation.includes(operation.id)" checked
                                                type="checkbox" name="opertion" id="operation" :value="operation.id" />
                                            <input @click="toogleOperation(operation.id)" v-else type="checkbox"
                                                name="opertion" id="operation" :value="operation.id" />
                                            <label class="form-label" for="operation">{{ operation.libelle }}</label>
                                        </div>
                                    </div>
                                    <div class="ml-5">
                                        <div v-for="role in listRole" :key="role.id">
                                            <input @click="toogleRole(role.id)" v-if="upNewUser.role.includes(role.id)"
                                                checked type="checkbox" name="role" id="role" :value="role.id" />
                                            <input @click="toogleRole(role.id)" v-else type="checkbox" name="role"
                                                id="role" :value="role.id" />
                                            <label class="form-label" for="role"> {{ role.libelle }} </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div @click="updateUser" class="btn btn-primary" data-dismiss="modal">
                                Confirmer
                            </div>
                            <div class="btn btn-primary ml-1" data-dismiss="modal">
                                Annuler
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="vld-parent">
        <loading v-model:active="isLoading" can-cancel="false" is-full-page="true" />
    </div>

    <FootVue />

</template>



<script>

import FootVue from '@/components/admin/fixed/FootVue.vue'
import HeadVue from '@/components/admin/fixed/HeadVue.vue'
import LoaderVue from '@/components/admin/fixed/Loader.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import axios from 'axios'
import Swal from 'sweetalert2'

export default {

    name: 'OperationView',
    components: {
        HeadVue,
        FootVue,
        LoaderVue,
        Loading
    },

    data() {
        return {
            load: false,
            isLoading: false,
            listOperation: [],
            listRole: [],
            listUser: [],
            newUser: {
                nom: '',
                prenoms: '',
                phone: '',
                email: '',
                password: '',
                operation: '0',
            },
            upNewUser: {
                id: 0,
                nom: '',
                prenoms: '',
                phone: '',
                email: '',
                role: [],
                operation: [],
            },
            beforeUp: {
                operation: [],
                role: [],
            },

            afterUp: {
                operation: [],
                role: [],
            }
        }
    },

    computed: {
        newUserFiledFull() {
            return (this.newUser.nom.length != 0 && this.newUser.prenoms.length != 0 && this.newUser.phone.length != 0 && this.newUser.email.length != 0 && this.newUser.password.length != 0 && this.newUser.operation != '0')
        },

        upNewUserFiledFull() {
            if (this.upNewUser.nom.length != 0 && this.upNewUser.prenoms.length != 0 && this.upNewUser.phone.length != 0 && this.upNewUser.email.length != 0 && this.upNewUser.password.length != 0) {
                return true
            } else {
                return false
            }
        }
    },

    methods: {
        getAllOperation() {
            axios.get(this.$store.state.serveur + 'api/geto/' + this.$store.state.user.entreprise_id)
                .then((response) => {
                    this.listOperation = response.data.data
                }).catch((error) => {
                    console.error(error)
                })
        },

        getAllRole() {
            axios.get(this.$store.state.serveur + 'api/roles')
                .then((response) => {
                    response.data.data.forEach((role) => {
                        if (role.id == 2 || role.id == 3) {
                            this.listRole.push(role)
                        }
                    })
                }).catch((error) => {
                    console.error(error)
                })
        },

        getAllUser(withLoad = false) {
            if (withLoad == true) {
                this.load = true
            }
            this.listUser = []
            axios.get(this.$store.state.serveur + 'api/users', {
                headers: { Authorization: "Bearer " + this.$store.state.token }
            }).then((response) => {
                console.log(response.data)
                this.listUser=response.data.data
                this.load = false
            }).catch((error) => {
                    this.load = false
                    console.error(error)
            })
        },

        addNewUser() {

            axios.post(this.$store.state.serveur + 'api/users', {
                nom: this.newUser.nom.toString(),
                prenom: this.newUser.prenoms.toString(),
                email: this.newUser.email.toString(),
                phone: this.newUser.phone.toString(),
                password: this.newUser.password.toString(),
                entreprise_id: this.$store.state.user.entreprise_id,
                roles_id: [2],
                operation: [this.newUser.operation]
            }).then((response) => {
                console.log(response)
                if (response.data.status == 'true') {
                    Swal.fire({
                        title: 'Utilisateur crée avec succès',
                        icon: 'success'
                    }).then(() => { this.$router.go() })
                } else {
                    Swal.fire({
                        title: 'Oops',
                        icon: 'error',
                        text: 'Cet utilisateur existe déja'
                    })
                }
            }).catch((error) => {
                console.error(error)
                Swal.fire({
                    title: 'Oops',
                    icon: 'error',
                    text: error.response.data.message
                })
            })
        },

        setUpNewUser(user) {
            let roles = []
            let operations = []

            this.upNewUser.id = user.id
            this.upNewUser.nom = user.nom
            this.upNewUser.prenoms = user.prenom
            this.upNewUser.email = user.email
            this.upNewUser.phone = user.phone
            user.role.forEach((role) => {
                roles.push(role.id)
            })
            this.upNewUser.role = roles
            user.operation.forEach((operation) => {
                operations.push(operation.id)
            })
            this.upNewUser.operation = operations
        },

        toogleOperation(operation_id) {
            if (this.upNewUser.operation.includes(operation_id)) {
                let operations = []
                this.upNewUser.operation.forEach((op) => {
                    if (op != operation_id) {
                        operations.push(op)
                    }
                })
                this.upNewUser.operation = operations
            } else {
                this.upNewUser.operation.push(operation_id)
            }
        },

        toogleRole(role_id) {
            if (this.upNewUser.role.includes(role_id)) {
                let roles = []
                this.upNewUser.role.forEach((ro) => {
                    if (ro != role_id) {
                        roles.push(ro)
                    }
                })
                this.upNewUser.role = roles
            } else {
                this.upNewUser.role.push(role_id)
            }
        },

        updateUser() {
            this.isLoading = true
            axios.put(this.$store.state.serveur + 'api/users/' + this.upNewUser.id, {
                nom: this.upNewUser.nom.toString(),
                prenom: this.upNewUser.prenoms.toString(),
                email: this.upNewUser.email.toString(),
                phone: this.upNewUser.phone.toString(),
                roles_id: this.upNewUser.role,
                operation: this.upNewUser.operation,
            }).then((response) => {
                this.isLoading = false
                console.log(response)
                this.getAllUser()
                if (response.data.status == 'true') {
                    Swal.fire({
                        title: 'Modification effectuée avec succès',
                        icon: 'success',
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        title: 'Oops',
                        icon: 'error',
                        text: 'La modification de cet utilisateur n\'est pas possible'
                    })
                }
            }).catch((error) => {
                this.isLoading = false
                console.error(error)
                Swal.fire({
                    title: 'Oops',
                    icon: 'error',
                    text: error.response.data.message
                })
            })
        },
    },

    created() {
        this.getAllOperation()
        this.getAllRole()
        this.getAllUser(true)
    }
}

</script>

<style>
.dropdown-item:hover {
    cursor: pointer;
}
</style>