<template>

    <div class="content-page">
            <div class="container-fluid container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header d-flex justify-content-between">
                                <div class="header-title">
                                    <h4 class="card-title">Qui somme nous ?</h4>
                                </div>
                            </div>
                            <div class="card-body">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been
                                    the industry's standard dummy text ever since the 1500s, when an unknown printer
                                    took a galley
                                    of type and scrambled it to make a type specimen book. It has survived not only five
                                    centuries,
                                    but also the leap into electronic typesetting, remaining essentially unchanged. It
                                    was
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                                    passages,
                                    and more recently with desktop publishing software like Aldus PageMaker including
                                    versions of
                                    Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header d-flex justify-content-between">
                                <div class="header-title">
                                    <h4 class="card-title">Nos objectifs</h4>
                                </div>
                            </div>
                            <div class="card-body">
                                <p>It is a long established fact that a reader will be distracted by the readable
                                    content of a page
                                    when looking at its layout. The point of using Lorem Ipsum is that it has a
                                    more-or-less normal
                                    distribution of letters, as opposed to using 'Content here, content here', making it
                                    look like
                                    readable English. Many desktop publishing packages and web page editors now use
                                    Lorem Ipsum as
                                    their default model text, and a search for 'lorem ipsum' will uncover many web sites
                                    still in
                                    their infancy. Various versions have evolved over the years, sometimes by accident,
                                    sometimes on
                                    purpose (injected humour and the like).</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header d-flex justify-content-between">
                                <div class="header-title">
                                    <h4 class="card-title">Nos cibles</h4>
                                </div>
                            </div>
                            <div class="card-body">
                                <p>It is a long established fact that a reader will be distracted by the readable
                                    content of a page
                                    when looking at its layout. The point of using Lorem Ipsum is that it has a
                                    more-or-less normal
                                    distribution of letters, as opposed to using 'Content here, content here', making it
                                    look like
                                    readable English. Many desktop publishing packages and web page editors now use
                                    Lorem Ipsum as
                                    their default model text, and a search for 'lorem ipsum' will uncover many web sites
                                    still in
                                    their infancy. Various versions have evolved over the years, sometimes by accident,
                                    sometimes on
                                    purpose (injected humour and the like).</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header d-flex justify-content-between">
                                <div class="header-title">
                                    <h4 class="card-title">Nos contacts</h4>
                                </div>
                            </div>
                            <div class="card-body">
                                <h4 class="text-info">E-Mail:</h4> <h5 class="text-warning">hotline@lce-ci.com</h5>
                                <h4 class="text-info">Téléphone:</h4> <h5 class="text-warning">+225 05 46 66 77 66 / +225 07 07 96 96 72</h5>
                                <h4 class="text-info">Site internet:</h4> <h5 class="text-warning"><a href="https://lce-ci.com/" target="blank">www.lce-ci.com</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

</template>
