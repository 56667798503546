<template>
    <div class="content-page">
        <LoaderVue v-if="load" />
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6" v-for="operation in dataOperation" :key="operation.id">
                    <div class="card card-block card-stretch card-height">
                        <div class="card-body rounded event-detail event-detail-info">
                            <div class="d-flex align-items-top justify-content-between">
                                <div>
                                    <h4 class="mb-2 mr-4">{{ operation.libelle }}</h4>
                                    <p>Choisissez une heure de RDV</p>
                                    <div class="row">
                                        <div v-if="operation.datechoise">
                                            <div style="margin: 5px" class="btn px-xl-4"
                                                v-for="o_time in operationHeures[operation.id]" :key="o_time.id"
                                                v-on:click="pickTime(o_time, operation.id,operation.datechoise)" :class="{
                                                    'btn-outline-info':
                                                        heureChoisi[operation.id].temps != o_time,
                                                    'btn-info': heureChoisi[operation.id].temps == o_time,
                                                    'disabled': tempsPris(
                                                        o_time,
                                                        dataRdvOperation[operation.id].temps,operation
                                                    ),
                                                }" data-toggle="modal" data-target="#create-event" aria-readonly="true">
                                                {{ o_time.heure }}H{{ o_time.minutes }}
                                            </div>
                                            <div @click="operation.datechoise = null" 
                                                style="margin: 5px" class="text-center btn px-xl-4 btn-secondary">
                                                Annuler
                                            </div>
                                            <div @click="
                                                finalRdv(heureChoisi[operation.id].temps, operation.id,operation.datechoise)" 
                                                style="margin: 5px" class="text-center btn px-xl-4 btn-primary"
                                                
                                                :class="{ disabled: !temps_picked[operation.id] }">
                                                Valider
                                            </div>
                                        </div>
                                        <div v-else>
                                            <date-picker
                                                v-model:value="operation.datechoise"
                                                valueType="format"
                                                @click="DateTruth = operation.days"
                                                @change="change(operation)"
                                                placeholder="Selectionnez la date"
                                                :disabled-date="disabledBeforeTodayAndAfterAWeek"
                                            >
                                            </date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Confirmation Modal-->
    <div class="modal fade create-workform" id="create-event" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="popup text-left">
                        <h4 class="mb-3 text-center">Finalisation du RDV</h4>
                        <div class="mt-3">
                            <div class="d-flex flex-wrap align-items-ceter justify-content-center">
                                <!--<div class="btn btn-primary mr-4" data-dismiss="modal">Anuller</div>-->
                                <div class="col-lg-6">
                                    <div class="floating-input form-group">
                                        <input v-model="u_nom" class="form-control" type="text" name="name" id="name"
                                            required />
                                        <label class="form-label" for="name">Nom </label>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="floating-input form-group">
                                        <input v-model="u_prenom" class="form-control" type="text" name="surname"
                                            id="surname" required />
                                        <label class="form-label" for="surname">Prénoms </label>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="floating-input form-group">
                                        <input v-model="u_email" class="form-control" type="email" name="email"
                                            id="email" required />
                                        <label class="form-label" for="email">E-Mail </label>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="floating-input form-group">
                                        <input v-model="u_contact" class="form-control" type="text" name="contact"
                                            id="contact" required />
                                        <label class="form-label" for="contact">Contact </label>
                                    </div>
                                </div>
                                <div class="card-body rounded event-detail event-detail1">
                                    <div class="d-flex justify-content-between mb-4">
                                        <h6 class="text-info">Date:</h6>
                                        <h6 class="text">{{ rdvInfo.date }}</h6>
                                    </div>
                                    <div class="d-flex justify-content-between mb-4">
                                        <h6 class="text-info">Heure:</h6>
                                        <h6 class="text">
                                            {{ rdvInfo.time.heure }}H{{ rdvInfo.time.minutes }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuler</button>
                                <button type="submit" class="btn btn-primary" :class="{ 'disabled': formFilled() == false }"
                                    @click="sendMail" data-dismiss="modal">
                                    Confirmer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="vld-parent">
        <loading v-model:active="isLoading" can-cancel="false" is-full-page="true" />
    </div>

</template>

<script>
import LoaderVue from "./admin/fixed/Loader.vue";
import axios from "axios"
import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import 'vue-datepicker-next/locale/fr';

export default {
    name: "CalendarContainerVue",

    components: {
        LoaderVue,
        Loading,
        DatePicker
    },

    props: {
        entrepriseId: {
            required: true,
        },

        entrepriseNom: {
            required: true,
        },

        secteurId: {
            required: true,
        },

        secteurLibelle: {
            required: true,
        },

        rdvDate: {
            required: true,
        },
    },

    data() {
        return {
            load: true,
            isLoading: false,
            dataOperation: null,
            entrepriseTime: null,
            dataError: null,
            rdvInfo: {
                time: { heure: 7, minutes: 0 },
                libelle: "null",
                date: this.rdvDate,
                id: null,
            },
            dataRdvOperation: {},
            operationHeures: {},
            heureChoisi: {},
            temps_picked: {},
            allRdv: [],
            allTemps: [],
            stat: null,
            u_nom: "",
            u_prenom: "",
            u_email: "",
            u_contact: "",
            u_code: "",
            DateTruth:'1,2,3,4,5,6,0',
            time:null
        };
    },

    computed: {

    },

    methods: {
        disabledBeforeTodayAndAfterAWeek(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            
            let arr = this.DateTruth.split(",");
            return date >= today && arr.find(element => Number(element) === date.getDay()) ? false : true ;
        },
        change(op) {

            const heure_debut = this.transforming(this.entrepriseTime.heure_debut)
            const heure_fin = this.transforming(this.entrepriseTime.heure_fin)
            const pause_debut = this.transforming(this.entrepriseTime.heure_pose)
            const pause_fin = this.transforming(this.entrepriseTime.heure_fpose)
            console.log('change',op)
            this.operationHeures[op.id] = this.generateTempsParOperation(heure_debut, heure_fin, pause_debut, pause_fin, op.quota, op.temps_passage)
            this.heureChoisi[op.id] = { selected: false, temps: null }
            axios.get(this.$store.state.serveur + "api/getdate/" + op.id + '/' + op.datechoise) 
                .then((res) => {
                    this.get_rdvs_then(res.data.data, op.id, op.quota, op.temps_passage)
                }).catch((e) => {
                    console.error(e)
                    this.dataRdvOperation[op.id] = {
                        temps: [],
                        quota: op.quota,
                        temps_passage: op.temps_passage,
                    }
                    this.load = false
                })
                console.log(op)
        },
        formFilled() {
            if (this.u_nom == "" || this.u_prenom == "" || this.u_email == "" || this.u_contact == "") {
                return false
            } else {
                return true
            }
        },

        pickTime(temps, id,date) {
            console.log('dddddd',temps)
            if (this.heureChoisi[id].selected == false) {
                this.heureChoisi[id] = { selected: true, temps: temps };
                this.temps_picked[id] = true;
            } else {
                this.heureChoisi[id] = { selected: false, temps: null };
                this.temps_picked[id] = false;
            }
           
            console.log('date',date)
            let info = {
                time: temps,
                date: date,
                id: id,
            };
            this.rdvInfo = info;
        
        },

        convertirMinute_h_m(min) {
            let h = 0;
            for (let i = min; i >= 60; i -= 60) {
                h++;
                min -= 60;
            }
            return { heure: parseInt(h), minutes: parseInt(min) };            
        },

        

        //passez du format 'heure:minutes' au format {heure: __, minutes: __}
        transforming(t) {
            let heure = "";
            let minutes = "";
            console.log('transforming',t)
            let dotIndex;
            for (let i = 0; i < t.length; i++) {
                if (t[i] == ":") {
                    dotIndex = i;
                }
            }
            for (let i = 0; i < t.length; i++) {
                if (i < dotIndex) {
                    heure += t[i];
                } else if (i > dotIndex) {
                    minutes += t[i];
                }
            }

            return { heure: parseInt(heure), minutes: parseInt(minutes) };
        },

        transforming_bar(t) {
            let heure = t.heure.toString();
            let minutes = t.minutes.toString();

            let new_t = heure + ":" + minutes;
            return new_t;
        },

        //genere les heures disponibles à choisir pour une opération
        generateTempsParOperation(debut, fin, p_debut, p_fin, quota, temps_passage) {
            let temps_total = []
            let temps_avantPause = []
            let temps_apresPause = []

            //On génère le temps total avant la pause
            let temps_memoire = { heure: debut.heure, minutes: debut.minutes }
            temps_avantPause.push(temps_memoire)
            for (let i = 1; i <= quota; i++) {

                //On génère le nouveau temps
                let t = temps_memoire.minutes + parseInt(temps_passage);
                let conv_t = this.convertirMinute_h_m(t);
                let new_t = {
                    heure: temps_memoire.heure + conv_t.heure,
                    minutes: conv_t.minutes,
                }

                //Le nouveau temps généré devient la memoire
                temps_memoire = new_t

                //On s'assure qu'il n'interfère pas avec le temps de pause de l'entreprise
                let temp = this.convertirMinute_h_m(new_t.minutes + parseInt(temps_passage))
                
                if (temp.heure + new_t.heure < p_debut.heure) {
                    temps_avantPause.push(new_t)
                } else if (temp.heure + new_t.heure == p_debut.heure) {
                    if (temp.minutes <= p_debut.minutes) {
                        //S'il n'interfère pas, on l'ajoute au temps total avant la pause
                        temps_avantPause.push(new_t)
                    }
                }
            }

            //On génère le temps total après la pause de la même façcon qu'avec le temps avant lapause..
            //en s'assurant cette fois si qu'il n'y a pas interférence avec l'heure de fin de l'entreprise
            temps_memoire = { heure: p_fin.heure, minutes: p_fin.minutes };
            temps_apresPause.push(temps_memoire);
            for (let i = 1; i <= quota; i++) {
                let t = temps_memoire.minutes + parseInt(temps_passage);
                let conv_t = this.convertirMinute_h_m(t);
                let new_t = {
                    heure: temps_memoire.heure + conv_t.heure,
                    minutes: conv_t.minutes,
                }

                temps_memoire = new_t

                let temp = this.convertirMinute_h_m(new_t.minutes + parseInt(temps_passage))
                if (temp.heure + new_t.heure < fin.heure) {
                    temps_apresPause.push(new_t)
                } else if (temp.heure + new_t.heure == fin.heure) {
                    if (temp.minutes <= fin.minutes) {
                        temps_apresPause.push(new_t)
                    }
                }
            }

            //On ajoute ces deux listes de temps au temps total
            
            temps_avantPause.forEach(tp => {
                if (tp.minutes ==0) {
                    tp.minutes = '00'
                }
                temps_total.push(tp)
            })
            
            temps_apresPause.forEach(tp => {
                temps_total.push(tp)
            })
            return temps_total;
        },

        //verifie si une heure de rdv a été sélctionnée
        tempsPris(temps, listeTemps,operation) {

            let estPris = false
            const today = new Date()
            const todayDate = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0')
            
            const now = {
                heure: parseInt(today.getHours()),
                minutes: parseInt(today.getMinutes())
            }
            const opStart =  this.transforming(operation.start)
            const opend =  this.transforming(operation.end)
           // console.log('start and end',temps, opStart, opend)
            listeTemps.forEach((time) => {
                if (time.heure == temps.heure && time.minutes == temps.minutes)
                    estPris = true
            })
            if (operation.datechoise == todayDate) {
                if (now.heure > temps.heure)
                    estPris = true
                else if (now.heure == temps.heure && now.minutes >= parseInt(temps.minutes) )
                    estPris = true
            }
            if (opStart.heure >= temps.heure) {
                if (opStart.heure > temps.heure ){
                    estPris = true
                }
                    
                else if ( opStart.heure == temps.heure && parseInt(opStart.minutes) >= parseInt(temps.minutes)) {
                    estPris = true
                }
            }
            if (opend.heure <= temps.heure) {
                estPris = true
                if (opend.heure == temps.heure && parseInt(opend.minutes)  >= parseInt(temps.minutes)) {
                    estPris = true
                }
            }

            return estPris;
        },

        //Génère le liste des heures de RDV qui sont déjà occuppées
        get_rdvs_then(response, o_id, o_quota, o_temps_passage) {
            this.allRdv = response
            let total_temps = [];
            
            this.allRdv.forEach((rdv) => {
                console.log('rdv',rdv)
                if (rdv.status == 0) {
                    
                    total_temps.push(this.transforming(rdv.heure))
                }
            })

            this.dataRdvOperation[o_id] = {
                temps: total_temps,
                quota: o_quota,
                temps_passage: o_temps_passage,
            }

            this.load = false
        },

        registerRdv(code) {
            if (code == this.u_code) {
                this.isLoading = true
                axios.post(this.$store.state.serveur + 'api/rdv', {
                    status: 0,
                    nom: this.u_nom.toString(),
                    prenom: this.u_prenom.toString(),
                    email: this.u_email.toString(),
                    phone: this.u_contact.toString(),
                    date: this.rdvInfo.date.toString(),
                    users_id: null,
                    heure: this.transforming_bar(this.rdvInfo.time).toString(),
                    operation_id: this.rdvInfo.id,
                })
                    .then((response) => {
                        this.isLoading = false
                        if (response.data.status == 'true') {
                            Swal.fire({
                                title: 'Félicitation. Rendez-vous pris avec succès',
                                text: 'Votre code Rendez-vous est ' + response.data.data.code,
                                icon: 'success',
                                allowOutsideClick: false,
                                allowEnterKey: false,
                                allowEscapeKey: false
                            }).then(() => {
                                this.$router.go()
                            })
                        } else {
                            Swal.fire({
                                title: 'Oops..',
                                text: response.data.data,
                                icon: 'error',
                            }).then(() => {
                                this.$router.go()
                            })
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false
                        console.log(e)
                        Swal.fire({
                            title: 'Oops..',
                            text: 'Vous avez déja un rendez-vous',
                            icon: 'error',
                        })
                        console.error(e)
                    })
            }
        },

        sendMail() {
            this.isLoading = true
            axios.post(this.$store.state.serveur + 'api/sendeMail', {
                email: this.u_email.toString(),
                phone: this.u_contact.toString(),
            }).then(async (response) => {
                    this.isLoading = false
                    this.u_code = response.data.data
                    let { value: cd } = await Swal.fire({
                        title: 'Confirmez le RDV',
                        titleText: 'Un code de confirmation vous a été envoyer par e-mail',
                        html: 'Ce pop-up ce fermera dans <b></b> secondes.',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showCloseButton: true,
                        inputLabel: 'Entrez le code',
                        input: 'text',
                        timer: 120000,
                        timerProgressBar: true,
                        didOpen: () => {
                            const b = Swal.getHtmlContainer().querySelector('b')
                            // eslint-disable-next-line no-unused-vars
                            let timerInterval = setInterval(() => {
                                b.textContent = Swal.getTimerLeft()/1000
                            }, 1000)
                        },
                        inputValidator: (value) => {
                            if (value.length == 0) {
                                return 'Le champ est vide.'
                            }
                            else if (value != this.u_code) {
                                return 'Code incorrect.'
                            }
                        }
                    })
                    this.registerRdv(cd)

                }).catch((error) => {
                    this.isLoading = false
                    console.error(error)
                    Swal.fire({
                        title: 'Oops..',
                        text: error.response.data.message,
                        icon: 'error',
                    })
                })
        },

        getTemps() {
            this.load = false
            axios
                .get(this.$store.state.serveur + "api/gettemps/" + this.entrepriseId)
                .then((response) => {
                    console.log(response)
                    this.getOperations() 
                    this.entrepriseTime = response.data.data[0]
                })
                .catch((e) => {
                    this.load = false
                    console.error(e)
                })
        },

        getOperations() {

            this.load = true
            axios.get(this.$store.state.serveur + "api/geto/" + this.entrepriseId)
                .then((response) => {
                    console.log('test',response)
                    this.dataOperation = response.data.data
                    this.load = false
                })
                .catch((error) => {
                    this.load = false
                    console.error(error)
                })
        },
    },

    mounted() {
        this.getTemps()
    },

    created() {

    },
}
</script>
