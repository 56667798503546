<template>

    <div class="content-page" style="background-image:url('assets/images/health.jpg') ;background-repeat: no-repeat;background-size: 100% 100%;">
        <div class="content-top" style="background-color: #288F36;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 col-md-8">
                        <ul class="d-flex nav nav-pills mb-4 text-center event-tab" id="event-pills-tab" role="tablist">
                            <li class="nav-item">
                                <a id="view-btn" class="nav-link active show" data-toggle="pill" href="#event1" data-extra="#search-with-button" role="tab" aria-selected="true">
                                    Specialité
                                </a>
                            </li>
                            <li class="nav-item">
                                <a id="view-schedule" class="nav-link" data-toggle="pill" href="#event2"
                                    data-extra="#view-event" role="tab" aria-selected="false">
                                    Mon RDV
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <LoaderVue v-if="load" />
            <div class="row">
                <div class="col-lg-12">
                    <div class="event-content">
                        <div id="event1" class="tab-pane fade active show">
                            <CalendarContainerVue :entrepriseId="1" :entrepriseNom="'Locomotive'" :secteurId="1" :secteurLibelle="'Informatique'"/>
                        </div>
                        <div id="event2" class="tab-pane fade active">
                            <div class="col-lg-12">
                                <div class="card card-block card-stretch">
                                    <div class="card-body">
                                        <div class="d-flex flex-wrap align-items-center justify-content-between">
                                            <h6 class="mb-3 mb-md-0">Code RDV</h6> 
                                            <input type="text" class="text search-input form-control api-password"
                                                placeholder="XXXX" v-model="codeRdvInput" />
                                            <div class="media flex-wrap api-link">
                                                <button class="btn btn-outline-primary px-xl-5" v-on:click="checkCode">
                                                     OK
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12" v-for="rdv in dataRdv" :key="rdv.id">
                                    <div class="card card-block card-stretch">
                                        <div class="card-body">
                                            <div class="d-flex flex-wrap align-items-center justify-content-between">
                                                <div v-if="rdv && operation_found && entreprise_found" class="d-flex flex-wrap align-items-center">
                                                    <div class="date mr-3">
                                                        <h4 class="text-info">
                                                            {{ dateRefactor(rdv.date).jour }} -
                                                            {{ dateRefactor(rdv.date).mois }} -
                                                            <span style="color: black;">{{ dateRefactor(rdv.date).annee
                                                            }}</span>
                                                        </h4>
                                                    </div>
                                                    <div class="border-left pl-3">
                                                        <div class="media align-items-top">
                                                            <h5 class="mb-3">{{ rdv.nom }} {{ rdv.prenom }}</h5>
                                                            <span class="ml-5 mt-1 badge badge-light">{{
                                                                    operation_found.libelle
                                                            }}</span>
                                                            <span class="ml-2 mt-1 badge badge-danger"
                                                                v-if="rdvExpired(rdv.date.slice(0, 10)+' '+rdv.heure) == true && rdv.status == 0">Expiré</span>                                          
                                                            <span class="ml-2 mt-1 badge badge-primary"
                                                                v-else-if="rdv.status == 0">En attente</span>
                                                            <span class="ml-2 mt-1 badge badge-danger" v-else-if="rdv.status == 2">
                                                                Annulé</span>
                                                        </div>
                                                        <div class="media align-items-center">
                                                            <p class="mb-0 pr-3">{{ rdv.phone }}</p>
                                                            <p class="mb-0 pr-3">{{ rdv.email }}</p>
                                                        </div>
                                                        <div class="media align-items-center">
                                                            <p class="mb-0 pr-3"><i
                                                                    class="ri-time-line mr-2 text-danger"></i>{{ rdv.heure
                                                                    }}</p>
                                                            <p class="mb-0">
                                                                <a href="https://www.google.com/maps/dir/5.3916188,-3.9572384/centre+m%C3%A9dical+des+enseignants/@5.376023,-4.0939383,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0xfc1c10f0c8f5e11:0x25e07e266319bc1f!2m2!1d-4.0929685!2d5.3394589" target="_bank">
                                                                    <i class="ri-map-pin-line mr-2 text-danger"></i>
                                                                </a> 
                                                                {{ entreprise_found.nom }}, {{ entreprise_found.lieu }}
                                                                {{ entreprise_found.adresse }} 
                                                            </p>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <div class="media flex-wrap api-link float-right" v-if="rdvExpired(rdv.date.slice(0, 10)+' '+rdv.heure) == false && rdv.status == 0">
                                                        <button class="btn btn-outline-primary px-xl-5"
                                                            data-input="#api-password" v-on:click="cancelRdv(rdv.id)">Annuler</button>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="!isCorrectCode && !load">
                                <div class="col-lg-4 col-md-6"></div>
                                <div class="col-lg-4 col-md-6">
                                    <div class="card card-block card-stretch card-height">
                                        <div class="card-body rounded event-detail event-detail1">
                                            <div class="d-flex justify-content-center mb-4">
                                                Pas de RDV de ce code
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5> -->
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Bienvenue au CENTRE MEDICAL DES ENSEIGNANTS !
                        Nous facilitons vos prises de rendez-vous.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import axios from 'axios'
    import LoaderVue from './admin/fixed/Loader.vue'
    import Swal from 'sweetalert2'
    import CalendarContainerVue from '@/components/CalendarContainerVue.vue';
        export default {

            name: 'app',

            components: {
                LoaderVue,
                CalendarContainerVue
            },

            data() {
                return {
                    load: true,
                    showRdv: false,
                    codeRdvInput: '',
                    codeAttempt: '',
                    dataSecteur: null,
                    dataRdv: null,
                    dataError: null,
                    secteurId: 0,
                    operation_found: null,
                    entreprise_found: null,
                }
            },

            computed: {

                isCorrectCode() {
                    let answer = false

                    if (this.dataRdv != null) {
                        if (this.dataRdv.length != 0) {
                            answer = true
                        }
                    }
                    return answer
                },
            },

            methods: {

                getSecteur() {
                    this.load = true
                    axios.get(this.$store.state.serveur + 'api/secteur')
                        .then((response) => {
                            this.load = false
                            this.dataSecteur = response.data.data
                        })
                        .catch(e => (this.dataError = e))
                },

                checkCode() {
                    this.load = true
                    this.codeAttempt = this.codeRdvInput
                    axios.get(this.$store.state.serveur + 'api/getcode/' + this.codeAttempt)
                        .then((response) => {
                            console.log(response.data)
                            this.dataRdv = response.data.data
                            if (this.dataRdv != null) {
                                if (this.dataRdv.length != 0) {
                                    axios.get(this.$store.state.serveur + 'api/operation/' + this.dataRdv[0].operation_id)
                                        .then((res) => {
                                            this.operation_found = res.data.data
                                            axios.get(this.$store.state.serveur + 'api/entreprise/' + this.operation_found.entreprise_id.toString())
                                                .then((r) => {
                                                    this.entreprise_found = r.data.data
                                                })
                                        })
                                }
                            }
                            this.load = false
                        })
                        .catch((error) => {
                            this.load = false
                            console.error(error)
                        })
                },
                cancelRdv(rdvId) {
                    this.load = true
                    this.codeAttempt = this.codeRdvInput
                    axios.post(this.$store.state.serveur + 'api/update1/' + rdvId, {
                                status: 2
                            })
                        .then((response) => {
                            console.log(response)
                            this.load = false
                            if (response.data.status == true) {
                                Swal.fire({
                                    title: 'Action effectué avec succès',
                                    icon: 'success',
                                    timer: 1500
                                })
                                this.dataRdv = response.data.data
                            }
                        })
                        .catch((error) => {
                            this.load = false
                            console.error(error)
                        })
                },

                dateRefactor(date) {
                    let new_date = ''
                    for (let i = 0; i < date.length; i++) {
                        if (date[i] != ' ') {
                            new_date += date[i]
                        } else {
                            break
                        }
                    }

                    let indexTiret1
                    let indexTiret1F = false
                    let indexTiret2
                    let annee = ''
                    let mois = ''
                    let jour = ''

                    for (let i = 0; i < new_date.length; i++) {
                        if (new_date[i] == '-' && !indexTiret1F) {
                            indexTiret1 = i
                            indexTiret1F = true
                        }

                        if (new_date[i] == '-') {
                            indexTiret2 = i
                        }
                    }

                    for (let i = 0; i < new_date.length; i++) {

                        if (i >= 0 && i < indexTiret1) {
                            annee += new_date[i]
                        }
                        else if (i > indexTiret1 && i < indexTiret2) {
                            mois += new_date[i]
                        } else if (i > indexTiret2) {
                            jour += new_date[i]
                        }
                    }

                    return { annee: annee, mois: mois, jour: jour }
                },

                rdvExpired(dt){
                    const now = new Date()
                    const date = new Date(dt)

                    const toReturn = now > date ? true : false
                    return toReturn
                }
            },

            created() {
                this.getSecteur()
            }

        };

</script>