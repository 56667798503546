<template>

    <footer class="iq-footer">
        <div class="container-fluid container">
            <div class="row text-center">
                <div class="col-lg-12 text">
                    Copyright 2022 <a href="https://lce-ci.com/" target="blank">LA LOCOMOTIVE</a> All Rights Reserved.
                </div>
            </div>
        </div>
    </footer>

</template>