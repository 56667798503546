<template>

    <HeadVue navHomeActive="true"/>
    <CalendarContainerVue :entrepriseId="this.$route.params.entId" :entrepriseNom="this.$route.params.entNom" :secteurId="this.$route.params.secId" :secteurLibelle="this.$route.params.secLib"/>
    <FootVue />

</template>

<script>
import FootVue from '@/components/FootVue.vue';
import HeadVue from '@/components/HeadVue.vue';
import CalendarContainerVue from '@/components/CalendarContainerVue.vue';
// @ is an alias to /src

export default {
  name: 'CalendarView',
  components: {
    HeadVue,
    FootVue,
    CalendarContainerVue
}
}
</script>
